[id="root"] {
    height: 100%;
}

body {
    overscroll-behavior-y: none; /* Disables pull-to-refresh and overscroll glow effect. Still keeps swipe navigations. (See: https://developer.chrome.com/blog/overscroll-behavior) */
}

/* antd */

[class="ant-descriptions-item-label"] {
    text-align: right !important;
}

[class="ant-layout"] {
    height: 100% !important;
    background-color: #FAFAFA !important;
}

[class="ant-layout-content"] {
    background-color: #FAFAFA !important;
    overflow-x: auto !important;
}

[class="ant-layout ant-layout-has-sider"] {
    min-height: unset !important;
    background-color: #FFFFFF !important;
}

[class="ant-upload ant-upload-select ant-upload-select-text"] {
    width: 100% !important;
}

[class="ant-table-header-column"] {
    width: 100% !important;
}

[class="ant-table-wrapper"] {
    background-color: #FFFFFF !important;
}

[class="ant-table-thead"] {
    background-color: #FAFAFA !important;
}

[class="ant-pagination ant-table-pagination mini"] {
    background-color: #FFFFFF !important;
}

[class="ant-table-body"] {
    margin: unset !important;
    overflow-x: auto !important;
}

[class="ant-back-top-content"] {
    border-radius: 5px !important;
}

[class="ant-card-body"] {
    padding: unset !important;
}

[class="ant-transfer-list"] {
    width: calc(50% - 20px) !important;
}

[class="ant-divider ant-divider-vertical"] {
    margin: 0 !important;
}

[class="ant-list-item-meta-avatar"] {
    margin-top: 8px !important;
    margin-left: 16px !important;
}

[class="ant-divider ant-divider-vertical"] {
    margin: 0 !important;
}

[class="anticon anticon-bell antd-pro-notice-icon-icon"] {
    font-size: large !important;
    padding-bottom: 5px !important;
}

[class="ant-list-item antd-pro-notice-icon-item-red"] {
    background-color: rgba(255, 0, 0, 0.1);
}

[class="ant-list antd-pro-notice-icon-list ant-list-split"] {
    height: 50vh !important;
}

[class="antd-pro-notice-icon-notFound"] {
    height: 50vh !important;
}

/* notifications */

.table-row-red-alert {
    height: 27px;
    background-color: rgba(255, 68, 68, 0.6);
}

.table-row-red {
    height: 27px;
    background-color: rgba(255, 0, 0, 0.1);
}

.table-row-yellow {
    height: 27px;
    background-color: rgba(255, 255, 0, 0.1);
}

.table-row-green {
    height: 27px;
    background-color: rgba(0, 255, 0, 0.1);
}

.table-row-blue {
    height: 27px;
    background-color: rgba(0, 0, 255, 0.1);
}

.table-row-orange {
    height: 27px;
    background-color: rgba(255, 165, 0, 0.1);
}

.table-row-grey {
    height: 27px;
    background-color: rgba(128, 128, 128, 0.1);
}

.table-row-white {
    height: 27px;
}

.mobile-table .ant-table-thead > tr {
    display: flex !important;
    flex-direction: column !important;
    border: 0 !important;
}

.mobile-table .ant-table-thead > tr > th {
    padding: 4px !important;
}

.mobile-table .ant-table-thead > tr > th:not(.ant-table-column-has-actions):not(.ant-table-column-has-filters) {
    display: none;
}

.mobile-table .ant-table-thead > tr > th:nth-last-child(2) {
    border: 0 !important;
}

.mobile-table .ant-table-thead > tr > th:last-child {
    padding: 0 !important;
}

.mobile-table .ant-btn {
    width: 100% !important;
    border-radius: 0 !important;
}

.truncate {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-list-split .ant-list-item:last-child {
    border-bottom-color: rgb(232, 232, 232) !important;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-right-color: rgb(232, 232, 232) !important;
    border-right-style: solid !important;
    border-right-width: 1px !important;
}

.notice-icon {
    float: right; 
    text-align: center;
    margin-right: 16px;
}

    @media (max-width: 592px) {
        .notice-icon {
            margin-right: 12px;
        }
    }

.notice-icon-disabled {
    float: right; 
    text-align: center;
    margin-right: 16px;
    cursor: not-allowed !important;
    color: rgba(0, 0, 0, 0.25) !important;
}

    @media (max-width: 592px) {
        .notice-icon-disabled {
            margin-right: 12px;
        }
    }

.svg-circle-mouseover {
    cursor: pointer !important;
}

.ant-pagination {
    display: none !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.popconfirm {
    display: unset !important;
}

.ant-table-tbody > tr > td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.ant-table-placeholder {
    z-index: auto !important;
}

/* archive */

.archive-row {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.archive-table .ant-table-thead > tr > th {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.archive-table .ant-form-item-control {
    line-height: 0 !important;
}

/* affix */

.affix {
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 1;
}

.affixWithNav {
    bottom: 0;
    position: fixed;
    width: calc(100% - 160px);
    z-index: 1;
}

/* video-js */

[class*="vjs_video"] {
    width: 100% !important;
    height: 100% !important;
}

.video-js {
    width: 100% !important;
    height: 100% !important;
}

.video-area {
    width: 100% !important;
    height: 100% !important;
}

.playerRow {
    background-color: black !important;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
}

.playerRowMulti {
    background-color: black !important;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
}

.playerCol {
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

.playerColMulti {
    padding: 0 !important;
    width: 50% !important;
    height: 100% !important;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-decoration: inherit;
}

.icon-left:before {
    content: "\f101";
    font-size: 1.615em;
    vertical-align: -0.5em;
    transform: rotate(180deg);
}

.icon-left-pip:before {
    content: "\f101";
    font-size: 1.615em;
    vertical-align: -0.5em;
    transform: rotate(180deg);
    opacity: 0;
    transition: 1s visibility, 1s opacity;
    transition-delay: 2s;
}

.icon-up:before {
    content: "\f101";
    font-size: 1.615em;
    vertical-align: -0.5em;
    transform: rotate(270deg);
}

.icon-up-pip:before {
    content: "\f101";
    font-size: 1.615em;
    vertical-align: -0.5em;
    transform: rotate(270deg);
    opacity: 0;
    transition: 1s visibility, 1s opacity;
    transition-delay: 2s;
}

.icon-down:before {
    content: "\f101";
    font-size: 1.615em;
    vertical-align: -0.5em;
    transform: rotate(90deg);
}

.icon-down-pip:before {
    content: "\f101";
    font-size: 1.615em;
    vertical-align: -0.5em;
    transform: rotate(90deg);
    opacity: 0;
    transition: 1s visibility, 1s opacity;
    transition-delay: 2s;
}

.icon-right:before {
    content: "\f101";
    font-size: 1.615em;
    vertical-align: -0.5em;
}

.icon-right-pip:before {
    content: "\f101";
    font-size: 1.615em;
    vertical-align: -0.5em;
    opacity: 0;
    transition: 1s visibility, 1s opacity;
    transition-delay: 2s;
}

.icon-zoomin:before {
    content: "\f115";
    font-size: 1.315em;
    /* vertical-align: -0.25em; */
    transform: rotate(45deg);
}

.icon-zoomout:before {
    content: "";
    position: relative; 
    height: 2px; 
    width: 10px; 
    background-color: white; 
    top: -3px; 
}

.icon-snapshot:before {
    content: "\f10b";
    font-size: 1.315em;
    vertical-align: -0.3em;
}

.icon-menu:before {
    content: "\f10e";
    font-size: 1.815em;
    vertical-align: -0.5em;
}

.icon-pip:before {
    content: "\f121";
    font-size: 1.315em;
    vertical-align: -0.3em;
}

.video-statistics {
    color: black;
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 2px 4px 2px 4px;
    background: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    font-weight: bold;
}

.video-area {
    position: relative;
}

.video-js .icon-left, 
.video-js .icon-left-pip, 
.video-js .icon-up, 
.video-js .icon-up-pip, 
.video-js .icon-down, 
.video-js .icon-down-pip, 
.video-js .icon-right, 
.video-js .icon-right-pip, 
.video-js .icon-zoomin, 
.video-js .icon-zoomout, 
.video-js .icon-menu, 
.video-js .icon-pip,
.video-js .icon-snapshot {
    cursor: pointer;
    -webkit-box-flex: none;
    -moz-box-flex: none;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
}

.video-js:hover .icon-up-pip:before,
.video-js:hover .icon-down-pip:before,
.video-js:hover .icon-left-pip:before,
.video-js:hover .icon-right-pip:before {
    opacity: 1;
    transition: 1s visibility, 1s opacity;
}

[class="vjs-spacer "] {
    width: 100%; /* full width for spacer */
}

.video-js .vjs-control {
    width: 3em !important; /* reduce controlbar elements width */
}

.video-js .vjs-time-control {
    width: auto !important;
}

.video-js .vjs-tech { 
    pointer-events: none; /* disable play/pause by click */
}

/* general */

.largeTopMenu {
    display: block;
}

.mediumTopMenu {
    display: none;
}

.smallTopMenu {
    display: none;
}

.mediumLayout {
    display: block;
    width: 100%;
}

.smallLayout {
    display: none;
    width: 100%;
}

.mediumPlayer {
    display: flex;
}

.smallPlayer {
    display: none;
}

@media (max-width: 1230px) {

    .largeTopMenu {
        display: none;
    }

    .mediumTopMenu {
        display: block;
    }

    .smallTopMenu {
        display: none;
    }
}

@media (max-width: 592px) {

    .largeTopMenu {
        display: none;
    }

    .mediumTopMenu {
        display: none;
    }

    .smallTopMenu {
        display: block;
    }

    .mediumLayout {
        display: none;
    }
    
    .smallLayout {
        display: contents;
    }

    .mediumPlayer {
        display: none;
    }

    .smallPlayer {
        display: flex;
    }

    .ant-form-item-label {
        margin-top: 12px !important
    }
}

.ant-drawer-body {
    padding: 0 !important;
}

.ant-table-wrapper,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-table,
.ant-table-content,
.ant-table-scroll,
.ant-table-body {
    height: 100%;
}

.ant-list-header {
    padding: 0 !important;
}

@supports (-webkit-overflow-scrolling: touch) { 
    select,
    textarea,
    input {
        font-size: 16px !important; /* disable zoom when focus form fields on iOS, see https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone */
    }
}

/* setup */

.responsiveButton {
    width: 100%;
    white-space: normal !important;
    min-height: 32px;
    height: auto !important;
}

/* connectivity */

.ant-list-item-action>li {
    padding-right: 0 !important;
}

/* maintenance */

.listItem {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.listButtonContainer {
    display: flex; /* Elemente nebeneinander */
    width: 100%; /* Breite des Containers */
    padding: 8px 8px 0 8px; /* Abstand zum Rand */
    gap: 8px; /* Abstand zwischen den Elementen */
    flex-wrap: wrap; /* Elemente werden umgebrochen */
    align-items: center; /* Elemente vertikal zentriert (Checkbox) */
}

.listButton {
    flex: 1 1 auto; /* Teilt den verfügbaren Platz gleichmäßig */
    overflow: hidden; /* Versteckt den überstehenden Inhalt */
}

.ant-list-item-meta-title {
    white-space: nowrap; /* Kein Zeilenumbruch */
}

.ant-list-item-meta-title,
.ant-list-item-meta {
    margin-bottom: 0 !important; /* Kein Abstand nach unten */
}

/* live footer */

.specialRadioButton {
    height: 100% !important;
    width: calc((100% - 44px) / 5);
    margin: 0px 4px 0px 4px !important;

    color: white !important;
    background: black !important;
    border-color: rgba(255, 255, 255, 0) !important;
}

.specialRadioButtonChecked {
    height: 100% !important;
    width: calc((100% - 44px) / 5);
    border-radius: 4px !important;
    margin: 0px 4px 0px 4px !important;
    
    color: white !important;
    background: rgba(255, 255, 255, 0.25) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    box-shadow: unset !important;
}

.specialRadioButtonChecked.ant-radio-button-wrapper:first-child {
    border-radius: 4px !important;
}

.specialRadioButton:not(:first-child)::before {
    background-color: unset !important;
}

.specialRadioButtonChecked::before {
    background-color: black !important;
    opacity: unset !important;
}

.specialRadioButtonChecked.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: rgba(255, 255, 255, 0) !important;
    opacity: unset !important;
}

.specialRadioButton.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled {
    color: rgba(255, 255, 255, 0.25) !important;
    background-color: black !important;
}

.specialRadioLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.specialRadioIcon {
    margin-top: 2px;
    font-size: 22px;
    margin-right: 12px;
    display: inline-block !important;
}

    @media (max-width: 1230px) {
        .specialRadioIcon {
            display: inline-block !important;
        }
    }

    @media (max-width: 592px) {
        .specialRadioIcon {
            display: none !important;
        }
    }

.specialRadioIconSmall {
    margin-top: 2px;
    font-size: 22px;
    display: none !important;
    line-height: 2 !important;
}

    @media (max-width: 1230px) {
        .specialRadioIconSmall {
            display: none !important;
        }
    }

    @media (max-width: 592px) {
        .specialRadioIconSmall {
            display: inline-block !important;
        }
    }

.specialRadioText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    display: block;
}

    @media (max-width: 1230px) {
        .specialRadioText {
            display: block;
        }
    }
    
    @media (max-width: 592px) {
        .specialRadioText {
            display: none;
        }
    }

.specialRadioTextSmall {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    display: none;
}

    @media (max-width: 1230px) {
        .specialRadioTextSmall {
            display: none;
        }
    }

    @media (max-width: 592px) {
        .specialRadioTextSmall {
            display: block;
        }
    }

.specialDivider {
    background: rgba(255, 255, 255, 0.25) !important;
    top: -0.3em !important;
    height: 80% !important;
    margin: 0 !important;
}

.specialDividerInvisible {
    background: rgba(255, 255, 255, 0) !important;
    top: -0.3em !important;
    height: 80% !important;
    margin: 0 !important;
}

.specialFooter {
    padding: 4px 0px 4px 0px !important;
    height: 64px;
    background: black !important;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.specialContent {
    overflow: hidden !important;
}

.ant-calendar-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 36px !important;
    line-height: unset !important;
}

.ant-calendar-time-picker-btn,
.ant-calendar-today-btn {
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 32px;
    padding: 0 15px;
    color: #fff;
    background-color: #EE3350;
    border-color: #EE3350;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
    line-height: 22px;
    text-transform: capitalize;
}

.ant-calendar-time-picker-btn:hover,
.ant-calendar-today-btn:hover {
    color: #fff;
    background-color: #fa5c6f;
    border-color: #fa5c6f;
}

.ant-calendar-today-btn.ant-calendar-today-btn-disabled,
.ant-calendar-today-btn.ant-calendar-today-btn-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* antd mobile */

:root:root {
    --adm-color-primary: #EE3350;
}